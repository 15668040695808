import React from "react";

import { StaticImage } from "gatsby-plugin-image";

import ButtonSolid from "../../components/Button/ButtonSolid";

const NeighborhoodSection1 = ({
  headingLevel,
  className,
  sectionTitle,
  sectionDesc,
}) => {
  return (
    <section className="relative mb-20 bg-primary-700 pt-12 md:mb-20 md:py-[2rem] xl:py-[8rem]">
      <div className="absolute bottom-0 left-0 hidden  md:block">
        <StaticImage
          src="../../images/3.0 Neighborhood/1.0 Neighborhood.jpg"
          loading="eager"
        />
      </div>
      <div className="container relative lg:py-24">
        <div className="grid gap-y-4 ">
          <div className="mx-auto grid grid-cols-1 text-center md:max-w-[840px] md:gap-y-4  ">
            <h1 className="md:text-white">{sectionTitle}</h1>
            <p className=" md:text-white">{sectionDesc}</p>
            <ButtonSolid
              class=""
              modal="modal-contact"
              text="Contact Us"
              className={
                "mx-auto  h-[48px] max-w-[180px] text-[16px] font-normal  "
              }
            />
          </div>
          <div className="-mx-4 md:mx-0 md:hidden">
            <StaticImage
              src="../../images/3.0 Neighborhood/1.0 Neighborhood-mobile.jpg"
              className="h-full w-full"
              height={350}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default NeighborhoodSection1;
