import React from "react";

import ButtonSolid from "../../components/Button/ButtonSolid";
// import ButtonGhost from "../../components/Button/ButtonGhost";

// import PortableText from "../../components/Blog/portableText";
import tw from "twin.macro";
import styled from "@emotion/styled";
import PortableText from "../Blog/portableText";

const StyledContent = styled.div`
  ul {
    ${tw`list-disc pl-2 mb-6 flex flex-col space-y-0.5`}
  }
  ol {
    ${tw`list-decimal pl-7 mb-6 flex flex-col space-y-0.5`}
  }
`;

const NeighborhoodSection2 = ({
  headingLevel,
  className,
  title1,
  description1,
  title2,
  description2,
  title3,
  description3,
  title4,
  description4,
  title5,
  description5,
}) => {
  return (
    <>
      <section className={` ${className || ""}`}>
        <div className="container">
          <div className="grid gap-y-10 md:gap-x-12 lg:gap-x-24">
            <div className="md:col-span-8 md:col-start-1">
              <div className="mb-12 md:mb-16">
                <StyledContent></StyledContent>
                <h2>{title1}</h2>
                <PortableText blocks={description1} />
              </div>
              <div className="mb-12 md:mb-16">
                <StyledContent></StyledContent>
                <h2>{title2}</h2>
                <PortableText blocks={description2} />
              </div>
              <div className="mb-12 md:mb-16">
                <StyledContent>
                  <h3>{title3}</h3>
                  <PortableText blocks={description3} />
                  <h3>{title4}</h3>
                  <PortableText blocks={description4} />
                </StyledContent>
                <ButtonSolid
                  className="w-[140px]"
                  modal="modal-contact"
                  text="Contact"
                />
              </div>
            </div>

            <div className="hidden md:col-span-4 md:col-end-13 md:block">
              <div className="md: bg-tertiary-50 sticky top-32 rounded-3xl p-10 md:rounded-3xl md:bg-secondary-400 md:p-10">
                <div>
                  <h3>{title5}</h3>
                  <StyledContent>
                    <PortableText blocks={description5} />
                  </StyledContent>
                  <ButtonSolid modal="modal-contact" text="Book a Visit" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NeighborhoodSection2;
